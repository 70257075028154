import React from "react"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import SpaceBox from "../elements/spaceBox"
import Paragraph from "../elements/paragraph"
import TimelineElement from "../elements/timelineElement"
import Reveal from "../elements/reveal"
import FadeIn from "../animations/fadeIn"
import SlideIn from "../animations/slideIn"

const Intro = () => {
  return (
    <SpaceBox>
      <Grid component="section" item xs={12} md={8}>
        <FadeIn>
          <Typography variant="h2" gutterBottom>
            My career highlights
          </Typography>
        </FadeIn>
        <FadeIn>
          <Paragraph>
            My first career highlight as a developer was working for Leighton, who 
            were contracted to provide all frontend development for the British Airways
            website. This was a huge step up from the small freelance projects I had 
            previously worked on.
          </Paragraph>
          <Paragraph>
            From Leighton I moved to Orange Bus, a digital agency in Newcastle upon Tyne.
            This is where I really cut my teeth as a developer, working with some very 
            talented people on range of different projects. I was promoted to Senior
            Developer and then Lead Developer during my time there.
          </Paragraph>
          <Paragraph>
            Orange Bus was taken over by Capita, and following an organisational restructure
            I was promoted to Lead Solutions Developer. This was a great opportunity to work
            closely with the sales team and help shape the technical direction of some very 
            high profile projects.
          </Paragraph>
          <Paragraph>
            In 2021 I joined Squeaky Energy as a Lead Software Engineer. This was a big change
            for me, moving from an agency environment to a product based company. At Squeaky I
            work closely with key stakeholders and the CTO to help shape the technical direction of the 
            software products we build. I manage a team of developers and oversee many of the projects
            we work on.
          </Paragraph>
        </FadeIn>
      </Grid>
      <Grid>
        <SlideIn threshold={0.3}>
          <Reveal>
            <TimelineElement />
          </Reveal>
        </SlideIn>
      </Grid>
    </SpaceBox>
  )
}

export default Intro
