import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const JoulesImg = ({ className, logo }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "clients/joules.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        name
      }
    }
  `)
  
  return (
    <div>
      <Img
        className={className}
        fluid={data.logo.childImageSharp.fluid}
        alt={data.logo.name}
      />
    </div>
  )
}

export default JoulesImg
