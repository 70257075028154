import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import Timeline from "@material-ui/lab/Timeline"
import styled from "styled-components"
import TimelineRecord from "./timelineRecord"

const useStyles = makeStyles(theme => ({
  sqLogo: {
    width: "100px",
    height: "30px",
  },
  obLogo: {
    width: "100px",
    height: "30px",
  },
  capLogo: {
    width: "100px",
    height: "32px",
  },
  leightonLogo: {
    width: "100px",
    height: "28px",
    marginBottom: "3px",
  },
  devereLogo: {
    width: "60px",
    height: "60px",
    marginBottom: "3px",
  },
  logoWrapLeft: {
    alignItems: "flex-start",
  },
  logoWrapRight: {
    alignItems: "flex-end",
  },
}))

const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const ObLogo = ({ className, logo }) => {
  const data = useStaticQuery(graphql`
    query {
      sq: file(relativePath: { eq: "squeaky-logo.png" }) {
        childImageSharp {
          fixed(width: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
      ob: file(relativePath: { eq: "orange-bus-logo.png" }) {
        childImageSharp {
          fixed(width: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
      leighton: file(relativePath: { eq: "leighton-logo.png" }) {
        childImageSharp {
          fixed(width: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
      capita: file(relativePath: { eq: "capita-logo.png" }) {
        childImageSharp {
          fixed(width: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
      dvro: file(relativePath: { eq: "devere-luxury-lodges-logo.png" }) {
        childImageSharp {
          fixed(width: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
      dvh: file(relativePath: { eq: "devere-hotels-logo.png" }) {
        childImageSharp {
          fixed(width: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
    }
  `)

  let logoFile, logoAlt
  switch (logo) {
    case "sq":
      logoFile = data.sq.childImageSharp.fixed
      logoAlt = data.sq.name
      break
    case "ob":
      logoFile = data.ob.childImageSharp.fixed
      logoAlt = data.ob.name
      break
    case "leighton":
      logoFile = data.leighton.childImageSharp.fixed
      logoAlt = data.leighton.name
      break
    case "capita":
      logoFile = data.capita.childImageSharp.fixed
      logoAlt = data.capita.name
      break
    case "dvro":
      logoFile = data.dvro.childImageSharp.fixed
      logoAlt = data.dvro.name
      break
    case "dvh":
      logoFile = data.dvh.childImageSharp.fixed
      logoAlt = data.dvh.name
      break

    default:
      break
  }

  return <Img className={className} fixed={logoFile} alt={logoAlt} />
}

export default function TimelineElement() {
  const classes = useStyles()

  return (
    <>
    <Timeline align="alternate">
      <TimelineRecord date="March 2021" position="Lead Software Engineer">
        <LogoWrap className={classes.logoWrapLeft}>
          <ObLogo className={classes.sqLogo} logo="sq" />
        </LogoWrap>
      </TimelineRecord>
      <TimelineRecord date="July 2020" position="Lead Solutions Developer">
        <LogoWrap className={classes.logoWrapRight}>
          <ObLogo className={classes.capLogo} logo="capita" />
        </LogoWrap>
      </TimelineRecord>
      <TimelineRecord
        date="January 2020"
        position="Lead Developer"
        logo="OB"
      >
        <LogoWrap className={classes.logoWrapLeft}>
          <ObLogo className={classes.obLogo} logo="ob" />
        </LogoWrap>
      </TimelineRecord>
      <TimelineRecord date="January 2017" position="Senior Developer">
        <LogoWrap className={classes.logoWrapRight}>
          <ObLogo className={classes.obLogo} logo="ob" />
        </LogoWrap>
      </TimelineRecord>
      <TimelineRecord date="April 2015" position="Developer">
        <LogoWrap className={classes.logoWrapLeft}>
          <ObLogo className={classes.obLogo} logo="ob" />
        </LogoWrap>
      </TimelineRecord>
      <TimelineRecord date="November 2013" position="Frontend Developer">
        <LogoWrap className={classes.logoWrapRight}>
          <ObLogo className={classes.leightonLogo} logo="leighton" />
        </LogoWrap>
      </TimelineRecord>
      <TimelineRecord
        date="April 2010"
        company="Freelance"
        position="Web Design and Development"
      />
      
      <TimelineRecord date="April 2002 - 2013" position="Sales Executive">
        <LogoWrap className={classes.logoWrapRight}>
          <ObLogo className={classes.devereLogo} logo="dvro" />
        </LogoWrap>
      </TimelineRecord>
      <TimelineRecord
        date="2001"
        company="Event"
        position="Built first website!"
      />
      <TimelineRecord date="September 1995" position="Fitness Instructor">
        <LogoWrap className={classes.logoWrapRight}>
          <ObLogo className={classes.devereLogo} logo="dvh" />
        </LogoWrap>
      </TimelineRecord>
    </Timeline>
    </>
  )
}
