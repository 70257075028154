import React from "react"
import Clients from "../components/pageSections/clients"
import Frameworks from "../components/pageSections/frameworks"
import Intro from "../components/pageSections/intro"
import Layout from "../components/pageSections/layout"
import SEO from "../components/pageSections/seo"
import Skills from "../components/pageSections/skills"
import TimelineSection from "../components/pageSections/timelineSection"
import BlogList from "../components/blog/blogList"
import Hobbies from "../components/pageSections/hobbies"

const IndexPage = () => (
  <Layout splash={true}>
    <SEO title="Profile" description="Portfolio and blog of Steve Newman, a Northumberland based full stack developer, with a strong interest in PHP, JavaScript, Drupal and React." />
    <Intro />
    <Skills />
    <Frameworks />
    <Hobbies />
    <TimelineSection />
    <Clients />
    <BlogList />
  </Layout>
)

export default IndexPage
