import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Switch from "@material-ui/core/Switch"
import Collapse from "@material-ui/core/Collapse"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"

const SwitchWrapper = styled.span`
  margin: 1rem auto;
`

const useStyles = makeStyles(theme => ({
  root: {
    height: 180,
  },
  switchBase: {
    "&$checked": {
      color: "grey[900]",
    },
  },
}))

const DisplaySwitch = withStyles({
  switchBase: {
    color: grey[300],
    "&$checked": {
      color: grey[900],
    },
    "&$checked + $track": {
      backgroundColor: grey[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

export default function Reveal({ children }) {
  const classes = useStyles()
  const [checked, setChecked] = React.useState(false)
  const [label, setLabel] = React.useState("Maximise timeline")

  const handleChange = () => {
    setChecked(prev => !prev)
    setLabel(
      label === "Display timeline" ? "Minimise timeline" : "Maximise timeline"
    )
  }

  return (
    <div>
      <Grid container>
        <SwitchWrapper>
          <FormControlLabel
            control={
              <DisplaySwitch
                className={classes.switchBase}
                checked={checked}
                onChange={handleChange}
              />
            }
            label={label}
          />
        </SwitchWrapper>
      </Grid>

      <div className={classes.container}>
        <Collapse in={checked} collapsedHeight={400}>
          <div> {children}</div>
        </Collapse>
      </div>
    </div>
  )
}
