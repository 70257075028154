import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import React from "react"
import FadeIn from "../animations/fadeIn"
import Paragraph from "../elements/paragraph"
import SkillsBar from "../elements/skillsBar"
import SpaceBox from "../elements/spaceBox"

const useStyles = makeStyles((theme) => ({
  skillGrid: {
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
}));

const Skills = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  let inline = matches
  const classes = useStyles();

  return (
    <SpaceBox>
      <Grid xs={12} container item>
        <FadeIn>
          <Typography variant="h2" gutterBottom>
            Skills and interests
          </Typography>
        </FadeIn>
      </Grid>

      <Grid xs={12} container item>
        <Grid xs={12} md={5} item>
          <FadeIn>
            <Paragraph>
              Like most developers I have a keen interest in all sorts of
              technologies but don't often get a chance to use them all
              commercially. This list is what I would consider my most frequently
              used programming languages and tools.
            </Paragraph>
            <Paragraph>
              Although I spent a number of years working with PHP for the lasr
              5 years I've been more focussed on full stack JavaScript. Most of my
              work now is with React and Node.js using a microservices architecture with RabbitMQ.
            </Paragraph>
            <Paragraph>
              I enjoy creating slick user interfaces with a focus on the ever increasing need
              to acheive AA accesibility standards. The creative aspect of frontend development
              is what drew me to this career and I still enjoy it as much now as I did when I started.
            </Paragraph>
            <Paragraph>
              I'm also firmly on the AI bandwagon and enjoy finding new ways to make the development
              process more efficient.
            </Paragraph>
          </FadeIn>
        </Grid>

        <Hidden smDown>
          <Grid xs={1} item />
        </Hidden>

        <Grid xs={12} md={6} item className={classes.skillGrid}>
          <SkillsBar value={90} skill="Javascript" inline={inline} />
          <SkillsBar value={70} skill="PHP" inline={inline} />
          <SkillsBar value={65} skill="Python" inline={inline} />
          <SkillsBar value={50} skill="Apex" inline={inline} />
          <SkillsBar value={95} skill="HTML" inline={inline} />
          <SkillsBar value={95} skill="CSS/SASS" inline={inline} />
          <SkillsBar value={90} skill="Git" inline={inline} />
          <SkillsBar value={85} skill="Linux/Unix" inline={inline} />
          <SkillsBar value={80} skill="Docker" inline={inline} />
          <SkillsBar value={85} skill="Microservices" inline={inline} />
          <SkillsBar value={85} skill="RabbitMQ" inline={inline} />
          <SkillsBar value={60} skill="AI" inline={inline} />
          <SkillsBar value={75} skill="CI/CD" inline={inline} />
          <SkillsBar value={75} skill="AWS" inline={inline} />
          <SkillsBar value={50} skill="Azure" inline={inline} />
          <SkillsBar value={85} skill="Jenkins" inline={inline} />
          <SkillsBar value={80} skill="REST/SOAP" inline={inline} />
          <SkillsBar value={80} skill="Postgres" inline={inline} />
          <SkillsBar value={80} skill="MySQL" inline={inline} />
        </Grid>
      </Grid>
    </SpaceBox>
  )
}

export default Skills
