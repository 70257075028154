import React from "react"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import SpaceBox from "../elements/spaceBox"
import SkillsBar from "../elements/skillsBar"
import Paragraph from "../elements/paragraph"
import FadeIn from "../animations/fadeIn"

const useStyles = makeStyles((theme) => ({
  skillGrid: {
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
}));

const Hobbies = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  let inline = matches
  const classes = useStyles();

  return (
    <SpaceBox>
      <Grid xs={12} container item>
        <FadeIn>
          <Typography variant="h2" gutterBottom>
            Hobbies
          </Typography>
        </FadeIn>
      </Grid>

      <Grid xs={12} container item>
        <Grid xs={12} md={5} item>
          <FadeIn>
            <Paragraph>
             My main hobby is golf, although not quite down to a single figure handicap yet but I'm getting close!
             I dabble with a bit of photography and enjoy playing darts and badminton when I get the chance.
            </Paragraph>
            <Paragraph>
              I try to fit in a run now and again but probably not as often as I should. I also try to make
              time to read, usually a mix of fiction and technical books.
            </Paragraph>
          </FadeIn>
        </Grid>
        <Hidden smDown>
          <Grid xs={1} item />
        </Hidden>

        <Grid xs={12} md={6} item className={classes.skillGrid}>
          <SkillsBar value={90} skill="Golf" inline={inline} />
          <SkillsBar value={55} skill="Photography" inline={inline} />
          <SkillsBar value={80} skill="Darts" inline={inline} />
          <SkillsBar value={55} skill="Badminton" inline={inline} />
          <SkillsBar value={80} skill="Reading" inline={inline} />
          <SkillsBar value={70} skill="Running" inline={inline} />
        </Grid>
      </Grid>
    </SpaceBox>
  )
}

export default Hobbies
