import React from "react"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import styled from "styled-components"
import SlideIn from "../animations/slideIn"

const BarWrapper = withStyles(theme => ({
  root: {
    position: "relative",
  },
}))(Grid)

const PercentageBar = withStyles(theme => ({
  root: {
    height: 25,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: "#d1d1d1",
  },
  bar: {
    borderRadius: 0,
    backgroundColor: "rgba(0, 0, 0, 0.87)",
  },
}))(LinearProgress)

const BarText = styled.span`
  position: absolute;
  left: 2%;
  padding-left: 5px;
  color: white;
  line-height: 25px;
  z-index: 99;
`
const BarTextInline = styled.span`
  display: inline-block;
  width: 100%;
  text-align: right;
  line-height: 25px;
  color: black;
`
const SkillsBar = ({ value, skill, inline }) => {
  if (inline) {
    return (
      <SlideIn slideLeft={true}>
        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item xs={2} />
          </Hidden>

          <BarWrapper item xs={12} md={10}>
            <BarText>{skill}</BarText>
            <PercentageBar variant="determinate" value={value} />
          </BarWrapper>
        </Grid>
      </SlideIn>
    )
  } else
    return (
      <SlideIn slideLeft={true}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <BarTextInline>{skill}</BarTextInline>
          </Grid>
          <Grid item xs={12} md={10}>
            <PercentageBar variant="determinate" value={value} />
          </Grid>
        </Grid>
      </SlideIn>
    )
}

export default SkillsBar
