import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import TimelineItem from "@material-ui/lab/TimelineItem"
import TimelineSeparator from "@material-ui/lab/TimelineSeparator"
import TimelineConnector from "@material-ui/lab/TimelineConnector"
import TimelineContent from "@material-ui/lab/TimelineContent"
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent"
import TimelineDot from "@material-ui/lab/TimelineDot"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import Paragraph from "./paragraph"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: "6px 16px",
  },
  timelineItem: {
    minHeight: "120px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  jobTitle: {
    fontSize: "18px",
  },
}))

const TimelineSection = styled(TimelineItem)`
  min-height: 150px;
`

const TimelineRecord = ({ children, date, company, position, logo }) => {
  const classes = useStyles()

  return (
    <TimelineSection>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary"></TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {children}
        <Typography variant="h4" component="h3">
          {company}
        </Typography>
        <Paragraph className={classes.jobTitle} gutterBottom>
          {position}
        </Paragraph>
      </TimelineContent>
    </TimelineSection>
  )
}

export default TimelineRecord
