import React, { useEffect } from "react"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import SpaceBox from "../elements/spaceBox"
import Paragraph from "../elements/paragraph"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import SlideIn from "../animations/slideIn"
import FadeIn from "../animations/fadeIn"

const Intro = () => {
  return (
    <SpaceBox>
      <Grid component="section" item xs={12} md={8}>
        <FadeIn thresh={0.1}>
          <Typography variant="h2" gutterBottom>
            Hey!
          </Typography>
        </FadeIn>
        <FadeIn thresh={0.3}>
          <Paragraph>
            My name is Steve Newman, I'm a full stack developer based in the North
            East of England.
          </Paragraph>
          <Paragraph>
            You could consider my journey to where I am now a little unorthodox.
            Having spent the first part of my career in the leisure and
            hospitality industry, mostly in sales and marketing, I decided to
            turn a long time hobby into a career in 2010.
          </Paragraph>
          <Paragraph>
            Starting out as freelance I worked on a number of small
            projects before landing my first big role 3 years later...Frontend
            Developer on the British Airways website!! The transition from
            Wordpress to a multi billion pound global website was pretty
            daunting but quite a milestone in my journey.
          </Paragraph>
          <Paragraph>
            After around 18 months at BA I moved on to a digital agency in Newcastle
            upon Tyne where I worked on a number of projects for clients such as
            Barbour, Joule, Racing Point plus many more. I was promoted to Senior Developer
            and then Lead Developer during my time there.
          </Paragraph>
          <Paragraph>
            Skipping forward a few years, I'm
            now a Lead Software Engineer for Squeaky Energy Ltd. Having spent a number
            of years working in an agency environment, I'm now enjoying the challenges
            of working in a product based company. I currently work remotely with occasional
            visits to the office in London.
          </Paragraph>
          <Paragraph>
            I enjoy all aspects of development, especially the problem solving and creative
            aspects of frontend development. I'm always looking for new challenges and ways
            to improve my skills.
          </Paragraph>
        </FadeIn>
      </Grid>
    </SpaceBox>
  )
}

export default Intro
