import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const SlideIn = ({
  children,
  animate = true,
  slideLeft = true,
  delay = 0,
  damp = 8,
  mass = 0.45,
  stiffness = 40,
  threshold = 0.9
}) => {

  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: threshold })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      //controls.start("hidden")
    }
  }, [controls, inView])

  const slideVars = () => {
    let x = slideLeft ? "100vw" : "-100vw"
    const variants = {
      visible: {
        x: 0,
        transition: {
          duration: 5,
          type: "spring",
          stiffness: stiffness,
          mass: mass,
          damping: damp,
        },
      },
      hidden: {
        x: x,
      },
    }
    return variants
  }

  if (!animate) {
    return <>{children}</>
  }

  return (
    <div ref={ref}>
      <motion.div animate={controls} initial="hidden" variants={slideVars()}>
        {children}
      </motion.div>
    </div>
  )
}

export default SlideIn
