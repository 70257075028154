import React from "react"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import SpaceBox from "../elements/spaceBox"
import SkillsBar from "../elements/skillsBar"
import Paragraph from "../elements/paragraph"
import FadeIn from "../animations/fadeIn"

const useStyles = makeStyles((theme) => ({
  skillGrid: {
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
}));

const Frameworks = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  let inline = matches
  const classes = useStyles();

  return (
    <SpaceBox>
      <Grid xs={12} container item>
        <FadeIn>
          <Typography variant="h2" gutterBottom>
            Platforms and frameworks
          </Typography>
        </FadeIn>
      </Grid>

      <Grid xs={12} container item>
        <Grid xs={12} md={5} item>
          <FadeIn>
            <Paragraph>
              Most of my frontend work over the past few years has been with React and
              Next.js, I find them both incredibly powerful and flexible to work
              with.
            </Paragraph>
            <Paragraph>
              At Squeaky we work with a microservices architecture, using Node.js and
              RabbitMQ to build our backend services. I've found this to be a really 
              interesting way of working and has helped me to understand the complexities
              of working with distributed systems.
            </Paragraph>
            <Paragraph>
              A lot of the projects I worked on at Orange Bus were built with Drupal.
              being a framework rather than a traditional CMS it is extremely powerful 
              and flexible to use.
            </Paragraph>
            <Paragraph>
              At Capita I spent some time developing with Salesforce, using Apex and lightning
              web components to build custom applications.
            </Paragraph>
          </FadeIn>
        </Grid>
        <Hidden smDown>
          <Grid xs={1} item />
        </Hidden>

        <Grid xs={12} md={6} item className={classes.skillGrid}>
          <SkillsBar value={90} skill="React" inline={inline} />
          <SkillsBar value={85} skill="Next.js" inline={inline} />
          <SkillsBar value={80} skill="Gatsby" inline={inline} />
          <SkillsBar value={85} skill="Node.js" inline={inline} />
          <SkillsBar value={80} skill="Express.js" inline={inline} />
          <SkillsBar value={70} skill="Drupal" inline={inline} />
          <SkillsBar value={45} skill="Django" inline={inline} />
          <SkillsBar value={75} skill="Wordpress" inline={inline} />
          <SkillsBar value={65} skill="Salesforce" inline={inline} />
        </Grid>
      </Grid>
    </SpaceBox>
  )
}

export default Frameworks
