import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import SpaceBox from "../elements/spaceBox"
import BarbourImg from "../images/barbour"
import JoulesImg from "../images/joules"
import BaImg from "../images/ba"
import SgnImg from "../images/sgn"
import IopcImg from "../images/iopc"
import SfiImg from "../images/sfi"
import Typography from "@material-ui/core/Typography"
import Paragraph from "../elements/paragraph"
import FadeIn from "../animations/fadeIn"
import SlideIn from "../animations/slideIn"

const LogoWrapper = styled(Grid)`
  margin-top: 0.5rem;
`

const Clients = () => {
  return (
    <SpaceBox>
      <Grid xs={12} container item>
        <FadeIn>
          <Typography variant="h2" gutterBottom>
            Key clients
          </Typography>
        </FadeIn>
      </Grid>

      <Grid xs={12} container item>
        <Grid xs={12} md={5} item>
          <FadeIn>
            <Paragraph>
              I've worked on a number of projects over the years but there are
              some that I consider to be a little bit more special. Working with
              large brands and industry leaders is always a privilege and these
              are my favourites so far.
            </Paragraph>
            <Paragraph variant="body2">
              Please note Barbour, Joules, Racing Point, SGN and IOPC were all
              Orange Bus clients and British Airways a client of Leighton. I
              worked on these projects under their respective employment.
            </Paragraph>
          </FadeIn>
        </Grid>
      </Grid>
      <SlideIn slideLeft={false}>
        <LogoWrapper
          container
          spacing={4}
          display="flex"
          alignItems="center"
          flexdirection="column"
        >
          <Grid item xs={4} sm={2}>
            <BarbourImg />
          </Grid>
          <Grid item xs={4} sm={2}>
            <JoulesImg />
          </Grid>
          <Grid item xs={4} sm={2}>
            <BaImg />
          </Grid>
          <Grid item xs={4} sm={2}>
            <SfiImg />
          </Grid>
          <Grid item xs={4} sm={2}>
            <SgnImg />
          </Grid>
          <Grid item xs={4} sm={2}>
            <IopcImg />
          </Grid>
        </LogoWrapper>
      </SlideIn>
    </SpaceBox>
  )
}

export default Clients
